import React, { useContext } from 'react';
import * as Pages from './components';
import Menu from './components/common/Menu';
import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom';
import { AuthContext } from './context/auth-context';
import Axios from 'axios';


function App() {

  const authContext = useContext(AuthContext);

  Axios.defaults.headers.common.AUTHORIZATION = 'Bearer ' + authContext.jwt;


  // let content = <Pages.Login />

  // let content = <div>hello</div>

  // let content = <Router>
  //   <Routes>
  //     <Route path='/' component={Pages.Login} />
  //   </Routes>
  // </Router>

  let content = <div className='container-fluid'>
      <Router>
        <Switch>
        <Route exact path='/join' element={<Pages.Join />} />
        <Route exact path='/search' element={<Pages.CoachingList />} />
        <Route exact path='/coaching-details/:id' element={<Pages.ItemDetails />} />
        <Route path='/' element={<Pages.Login />} />
        <Route path='*' element={<Pages.Login />} />
        </Switch>
      </Router>
    </div>
  if (authContext.isAuth && authContext.itemId) content = (
    <div className='container-fluid'>
      <Router>
        <Menu />
        <div style={{ height: '20px' }}></div>
        <Switch>
          <Route exact path='/' element={<Pages.CoachingListAdmin />} />
          <Route exact path='/sign-up' element={<Pages.SignUp />} />
          <Route exact path='/logout' element={<Pages.LogOut />} />
          <Route exact path='/categories' element={<Pages.Category />} />
          <Route exact path='/features' element={<Pages.Feature />} />
          <Route exact path='/roles' element={<Pages.Role />} />
          <Route exact path='/menus' element={<Pages.Menu />} />
          <Route exact path='/clients' element={<Pages.Clients />} />
          <Route exact path='/client' >
            <Route exact path=':id' element={<Pages.Client />} />
          </Route>
          <Route exact path='/page' >
            <Route exact path=':id' element={<Pages.SinglePage />} />
          </Route>
          <Route exact path='/pages' element={<Pages.Pages />} />

          <Route exact path='/blog' >
            <Route exact path=':id' element={<Pages.SingleBlog />} />
          </Route>


          <Route exact path='/users' element={<Pages.Users />} />
          <Route exact path='/user' >
            <Route exact path=':id' element={<Pages.SingleUser />} />
          </Route>
          <Route exact path='/manage-client' >
            <Route exact path=':id/:email' element={<Pages.ManageSingleClient />} />
          </Route>
          <Route exact path='/add-user' element={<Pages.AddUser />} />
          <Route exact path='/local-areas' element={<Pages.LocalAreas />} />
          <Route exact path='/countries' element={<Pages.Countries />} />
          <Route exact path='/states' element={<Pages.States />} />
          <Route exact path='/districts' element={<Pages.Districts />} />
          <Route exact path='/self' element={<Pages.Self />} />
          <Route exact path='/sliders' element={<Pages.Slider />} />
          <Route exact path='/educational-solutions' element={<Pages.EducationSolution />} />
          <Route exact path='/enroll-today' element={<Pages.EnrollToday />} />
          <Route exact path='/register-now' element={<Pages.RegisterNow />} />
          <Route exact path='/teachers' element={<Pages.Teacher />} />
          <Route exact path='/testimonials' element={<Pages.Testimonial />} />
          <Route exact path='/events' element={<Pages.UpcomingEvents />} />
          <Route exact path='/generals' element={<Pages.Generals />} />
          <Route exact path='/event/:id' element={<Pages.SingleEvent />} />
          <Route exact path='/general/:id' element={<Pages.SingleGeneral />} />
          <Route exact path='/password' element={<Pages.ChangePassword />} />
          <Route exact path='/add-event' element={<Pages.AddEvent />} />
          <Route exact path='/add-general' element={<Pages.AddGeneral />} />
          <Route exact path='/add-page' element={<Pages.AddPage />} />
          <Route exact path='/add-blog' element={<Pages.AddBlog />} />
          <Route exact path='/blogs' element={<Pages.Blogs />} />
          <Route exact path='/fun-facts' element={<Pages.FunFacts />} />
          <Route exact path='/images' element={<Pages.Images />} />
          <Route exact path='/videos' element={<Pages.YouTubeVideos />} />
          <Route exact path='/settings' element={<Pages.Settings />} />
          <Route component={Pages.NotFound} />
        </Switch>
      </Router>
    </div>
  )

  return (
    content
  );
}

export default App;
