import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Input from './common/Input';
import MyCard from './common/Card';
import { useForm } from 'react-hook-form';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import Geocode from "react-geocode";

const CoachingList = () => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const [stateId, setStateId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [countryId, setCountryId] = useState('1');
    const [localArea, setLocalArea] = useState('');

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    const loadInitialItems = () => {
        coreContext.fetchClients(1);
    }

    useEffect(loadInitialItems, []);

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }

    const showPosition = (position) => {
        console.log("Latitude: " + position.coords.latitude +
            "<br>Longitude: " + position.coords.longitude +
            "<br>Accuracy: " + position.coords.accuracy);

        codeLatLng(position.coords.latitude, position.coords.longitude);
    }

    const codeLatLng = (lat, lng) => {
        Geocode.setApiKey("AIzaSyDCuw_jbn8R3pblxRP4QJFg2kr02CsZQZM");
        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address;
                console.log(address);
            },
            error => {
                console.error(error);
            }
        );
    }

    const onLocalAreaChangedHandler = (e) => {
        setLocalArea(e.target.value);
    }

    const onStateChangedHandler = (e) => {
        setStateId(e.target.value);
        coreContext.fetchDistricts(countryId, e.target.value);
    }

    const onDistrictChangedHandler = (e) => {
        setDistrictId(e.target.value);

        coreContext.fetchAllLocalAreas(1, stateId, e.target.value);
    }

    const loadLoactionOptions = () => {

        coreContext.fetchStates('1');
        coreContext.fetchDistricts(1, 1);
        coreContext.fetchAllLocalAreas(1, 1, 1);
    }



    const onSearchButtonClickedHandler = () => {

        coreContext.searchClients(stateId, districtId);
    }


    const renderClients = () => {
        if (coreContext.clients) {
            return coreContext.clients.map((client, index) => (
                <div className="col-sm-6">
                    <MyCard key={client.id} id={client.id} name={client.item_name} tag={client.item_tag} images={client.images} videos={client.video_links} district={client.district_name} />
                </div>

            ));
        }
    }

    useEffect(loadLoactionOptions, [coreContext.client]);


    return (
        <div className='card mycard'>
            <div className="card-header">
                <h3> <center><span className="badge badge-success p-4">Find Coaching Institutes <br /> In Your Area </span></center></h3>

            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-4">  <Button onClick={getLocation}>Use My Current Location</Button></div>
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-md-4">
                                <Input label='' name='state' required={true} register={register} errors={errors} elementType='select' value={stateId} options={coreContext.stateOptions} onChange={onStateChangedHandler} />
                            </div>
                            <div className="col-md-4">
                                <Input label='' name='district' required={true} register={register} errors={errors} elementType='select' value={districtId} options={coreContext.districtOptions} onChange={onDistrictChangedHandler} />
                            </div>
                            <div className="col-md-4">
                                {/* <Input label='' name='localarea' required={false} register={register} errors={errors} elementType='select' value={localArea} options={coreContext.localAreaOptions} onChange={onLocalAreaChangedHandler} /> <br /> */}


                                <Input value='Search' onClick={onSearchButtonClickedHandler} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    {renderClients()}
                </div>
            </div>
        </div>
    );

}

export { CoachingList };