/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ToastContainer, toast } from 'react-toastify';
import ImageUploader from './common/UploadImage';
import Input from './common/Input';
import { XSquare } from 'react-bootstrap-icons';
import { Badge } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';

import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';



const Images = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

    const fetchClient = () => {
        coreContext.fetchSelfImages(authContext.itemId);
    }

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', authContext.itemId);
            formData.append('pageId', coreContext.page.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            formData.append('itype', 'image');
            coreContext.uploadImage(formData, authContext.itemId, coreContext.page.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchSelfImages(authContext.itemId);
                }, 2000);
            };
        }
    }

    const deleteImage = (id) => {

        coreContext.deleteImage(id);
        deleteNotify();

    }

    const notify = () => toast("Image path copied to clipboard");
    const deleteNotify = () => toast("Image deleted successfully");

    const copyURL = (url) => {
        var aux = document.createElement("input");

        // Get the text from the element passed into the input
        aux.setAttribute("value", url);

        // Append the aux input to the body
        document.body.appendChild(aux);

        // Highlight the content
        aux.select();

        // Execute the copy command
        document.execCommand("copy");

        // Remove the input from the body
        document.body.removeChild(aux);

        notify();
    }

    useEffect(fetchClient, []);

    const renderImages = () => {
        const images = coreContext.client.images;
        if (images)
            return images.map(image => {
                let src;
                if (image.url.includes('.pdf'))
                    //src = 'http://localhost:4040/images/pdfimage.png';
                    src = 'https://node-websites.siddhantait.com/images/pdfimage.png';
                else src = 'http://' + image.url;
                return <div className='col-md-3'><LazyLoadImage
                    alt={image.alt}
                    src={src} // use normal <img> attributes as props
                    placeholderSrc={src}
                    effect="blur"
                    className="img-thumbnail"
                    style={{ padding: '5px' }}
                />
                    <span title='Click to copy URL' style={{ 'cursor': 'pointer', fontSize: 'x-small' }} onClick={() => copyURL("http://" + image.url)}>{"http://" + image.url}</span>
                    &nbsp;&nbsp; <button onClick={() => deleteImage(image.id)} type="button" className="btn btn-danger btn-sm ml-4" title="Delete Image"> <XSquare size="12" /> </button>
                </div>
            })
    }




    return (<div className='container-fluid'>
        <div className="card">

            <div className="card-title mx-auto"><h3><Badge variant="success">Upload Images</Badge></h3></div>

            <div className="card-body">
                <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='1920' pictures={selectedImages} />
                <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </div>

        </div>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Website Images / Docs</Badge></h3></div>
            <div className="card-body">
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
        <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={true}
            type='success'
        />

        {/* <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        /> */}

    </div>
    )
}

export { Images }