import React from 'react';

const NotFound = () => {

    return (

        <center className='h2'>Sorry! This page does not exist..</center>
    );

}

export { NotFound };