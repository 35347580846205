/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';

import Input from './common/Input';
import { Form } from 'react-bootstrap';
import Axios from 'axios';
import { XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const SingleEducationalSolutionImage = (props) => {



    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const fetchClient = () => {
        coreContext.fetchSelfEducationalSolutions(authContext.itemId);

        setValue('imagetitle', props.image.imagetitle);
        setValue('imagemessage', props.image.imagemessage);
        setValue('button1title', props.image.button1title);
        setValue('button1link', props.image.button1link);
    }

    useEffect(fetchClient, []);


    const { register, handleSubmit, errors, setValue } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    // const onSubmit = data => console.log(data);

    const onImageDetailsHandler = (data) => {
        const { imagetitle, imagemessage, button1title, button1link } = data;
        coreContext.updateEducationalSolutionsImage(imagetitle, imagemessage, button1title, button1link, authContext.itemId, props.image.id);
    }



    const deleteImage = () => {
        const id = props.image.id;
        deleteEducationalSolutionsImage(id);
    }

    const deleteEducationalSolutionsImage = (id) => {

        Axios.post('/delete-educational-solutions-image', { id }).then((response) => {
            const images = coreContext.educationalSolutionsImages;
            const newImages = images.filter(image => image.id !== id);
            coreContext.setEducationalSolutionsImages(newImages);
        }).catch(err => { });
    }

    return (
        <div className="col-md-3"><img style={{ padding: 5 }} className="img-fluid" src={`http://${props.image.url}`} alt='gallery image' />
            <Form autoComplete='off' onSubmit={handleSubmit(onImageDetailsHandler)} noValidate>
                <Input label='Image Title' name='imagetitle' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Title' />

                <Input label='Image Message' name='imagemessage' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Message' />

                <Input label='Button-1 Title' name='button1title' minLength={2} maxLength={25} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Button-1 Title' />

                <Input label='Button-1 Link' name='button1link' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Button-1 Link' />

                <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; <button onClick={() => deleteImage()} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button>&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </Form>

        </div>
    )
}

export { SingleEducationalSolutionImage }