/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';

import Input from './common/Input';
import { Form } from 'react-bootstrap';
import { XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const SingleTeachersImage = (props) => {

    const [imageTitle, setImageTitle] = useState(props.image.imagetitle);
    const [imageMessage, setImageMessage] = useState(props.image.imagemessage);
    const [imageDesignation, setImageDesignation] = useState(props.image.imagedesignation);
    const [link1, setLink1] = useState(props.image.link1);
    const [link2, setLink2] = useState(props.image.link2);
    const [link3, setLink3] = useState(props.image.link3);
    const [link4, setLink4] = useState(props.image.link4);
    const [message, setMessage] = useState('');


    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const fetchClient = () => {
        coreContext.fetchSelfTeachers(authContext.itemId);
    }

    useEffect(fetchClient, []);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    // const onSubmit = data => console.log(data);

    const onImageDetailsHandler = (data) => {
        coreContext.updateTeachersImage(imageTitle, imageMessage, imageDesignation, link1, link2, link3, link4, authContext.itemId, props.image.id);

    }

    const onImageTitleChangedHandler = (e) => {
        setImageTitle(e.target.value);
    }

    const onImageMessageChangedHandler = (e) => {
        setImageMessage(e.target.value);
    }

    const onImageDesignationChangedHandler = (e) => {
        setImageDesignation(e.target.value);
    }

    const onLink1ChangedHandler = (e) => {
        setLink1(e.target.value);
    }
    const onLink2ChangedHandler = (e) => {
        setLink2(e.target.value);
    }
    const onLink3ChangedHandler = (e) => {
        setLink3(e.target.value);
    }

    const onLink4ChangedHandler = (e) => {
        setLink4(e.target.value);
    }

    const deleteImage = () => {
        const id = props.image.id;
        const images = coreContext.teachersImages;
        const newImages = images.filter(image => image.id !== id);
        coreContext.setTeachersImages(newImages);
        coreContext.deleteTeachersImage(id);
    }


    return (
        <div className="col-md-3"><img style={{ padding: 5 }} className="img-fluid" src={`http://${props.image.url}`} alt='gallery image' />
            <Form autoComplete='off' onSubmit={handleSubmit(onImageDetailsHandler)} noValidate>
                <Input label='Image Title' value={imageTitle} name='imagtitle' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Title' onChange={onImageTitleChangedHandler} />

                <Input label='Image Title' value={imageDesignation} name='imagedesignation' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Designation' onChange={onImageDesignationChangedHandler} />

                <Input label='Image Message' value={imageMessage} name='imagemessage' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Message' onChange={onImageMessageChangedHandler} />

                <Input label='Link 1' value={link1} name='link1' minLength={2} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Link 1' onChange={onLink1ChangedHandler} />

                <Input label='Link 2' value={link2} name='link2' minLength={2} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Link 2' onChange={onLink2ChangedHandler} />

                <Input label='Link 3' value={link3} name='link3' minLength={2} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Link 3' onChange={onLink3ChangedHandler} />

                <Input label='Link 4' value={link4} name='link4' minLength={2} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Link 4' onChange={onLink4ChangedHandler} />

                <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                &nbsp; &nbsp;  <button onClick={() => deleteImage()} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button>
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </Form>

        </div>
    )
}

export { SingleTeachersImage }