import React, { useState, Fragment, useEffect, useContext } from 'react';
import { Card, Button } from 'react-bootstrap';
import Input from './common/Input';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { CoreContext } from '../context/core-context';
const ItemDetails = (props) => {

    const fetchClient = () => {

        coreContext.searchClient(props.location);
    }

    useEffect(fetchClient, []);

    const coreContext = useContext(CoreContext);

    const renderImages = () => {
        if (coreContext.client.images)
            return coreContext.client.images.map(img => <div key={img.id}>
                <img src={`http://${img.url}`} alt='slider' />
            </div>)
        return <div></div>;
    }

    const renderVideos = () => {
        if (coreContext.client.video_links)
            return coreContext.client.video_links.map(video => <div key={video.id} dangerouslySetInnerHTML={{ __html: video.url }} >
            </div>)

        return <div></div>;
    }



    return (
        <Card style={{ margin: '10px' }} className="text-center">
            <Card.Header style={{ backgroundColor: '#cce6ff', fontWeight: 'bold', fontSize: 'large' }}>{coreContext.client.item_name}</Card.Header>
            <Card.Footer style={{ backgroundColor: '#ccfff5' }} className="text-muted">Ratings</Card.Footer>
            <Card.Body style={{ backgroundColor: '#ffb3cc' }}>
                <Card.Title>{coreContext.client.item_tag ? coreContext.client.item_tag : 'No Tag Mentioned'}</Card.Title>
                <Card.Text>
                    Contact No : XXXXXXXXXX
</Card.Text>
                <Card.Text>
                    Address: XXXXXXXXXX
                </Card.Text>
                {/* <Button variant="primary" onClick={showMore}>{showMoreLabel}</Button> */}
            </Card.Body>

            <Card.Body className='mycard'>
                <div className="row">
                    <div className="col-md-4">
                        <Input elementType='button' variant='primary' value="Enroll" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                        <br /> <br />

                        <Input elementType='button' variant='primary' value="Rate This Coaching" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                        <br /> <br />
                    </div>

                    <div className="col-md-4">
                        <Card.Header style={{ backgroundColor: '#ffccff', fontWeight: 'bold', fontSize: 'large' }}>Images</Card.Header>
                        <Carousel showIndicators={true}>
                            {renderImages()}
                        </Carousel>
                    </div>


                    <div className="col-md-4">
                        <Card.Header style={{ backgroundColor: '#ffccff', fontWeight: 'bold', fontSize: 'large' }}>Videos</Card.Header>
                        <Carousel showIndicators={true}>
                            {renderVideos()}
                        </Carousel>
                    </div>
                </div>
            </Card.Body>


        </Card>
    );
}


export { ItemDetails };