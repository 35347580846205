/* eslint-disable no-multi-str */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import ImageUploader from './common/UploadImage';
import { Editor } from '@tinymce/tinymce-react';
import Axios from 'axios';
import Input from './common/Input';
import { Badge, Form } from 'react-bootstrap';
import { XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from 'react-router-dom';

const SingleBlog = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState('active');
    const [statusOptions, setStatusOptions] = useState([{ value: 'active', name: 'Active' }, { value: 'inactive', name: 'Inactive' }]);
    const [message, setMessage] = useState('');
    const [content, setContent] = useState('');
    const [images, setImages] = useState([]);
    const [edate, setEdate] = useState('');
    const [cat, setCat] = useState('');
    const [showInPopUP, setShowInPopUP] = useState('no');

    const navigate = useNavigate();
    const location = useLocation();

    const fetchClient = () => {
        coreContext.fetchBlog(location, authContext.itemId);
    }

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({});

    useEffect(fetchClient, []);

    const populateClient = () => {
        setValue('title', coreContext.blog.title);
        setValue('message', coreContext.blog.message);
        setContent(coreContext.blog.pageContent);
        setValue('edate', coreContext.blog.bdate);
        setValue('cat', coreContext.blog.bcategory);
        setShowInPopUP(coreContext.blog.show_in_popup);
        setImages(coreContext.blog.blog_images);
    }

    useEffect(populateClient, [coreContext.blog]);

    const deleteImage = (id) => {

        deleteBlogImage(id);
    }

    const deleteBlogImage = (id) => {

        Axios.post('/delete-blog-image', { id }).then((response) => {
            const newImages = images.filter(image => image.id !== id);
            setImages(newImages);
        }).catch(err => { });
    }




    const renderImages = () => {
        if (images)
            return images.map(image => <div style={{ position: 'relative' }} key={image.id} className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`http://${image.url}`} alt='gallery image' /> <button onClick={() => deleteImage(image.id)} style={{ position: 'absolute', top: 0, left: 5 }} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button></div>)
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', authContext.itemId);
            formData.append('blogId', coreContext.blog.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadBlogImage(formData, authContext.itemId, coreContext.blog.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchBlogImages(authContext.itemId, coreContext.blog.id);
                }, 2000);

                setTimeout(() => {
                    navigate('/blogs');
                }, 5000);
            };
        }
    }



    const handleEditorChange = (content, editor) => {
        setContent(content);
    }


    const showInPopUpChangeHandler = (e) => {
        if (showInPopUP === 'yes')
            setShowInPopUP('no');
        else
            setShowInPopUP('yes');
    }

    const onBlogContentHandler = (data) => {
        coreContext.updateBlogContent(data.title, data.message, content, data.edate, data.cat, showInPopUP, data.status, authContext.itemId, coreContext.blog.id);

    }

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Edit Blog</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onBlogContentHandler)} noValidate>
                    <Input label='Title' name='title' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Title' />

                    <Input label='Image Message' name='message' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Message' />

                    <div className="row"><div className="col-md-3">
                        <Input label='Blog Date' name='edate' minLength={2} maxLength={50} required={true} register={register} errors={errors} elementType='date' placeholder='Enter Blog Date' />
                    </div>
                        <div className="col-md-3">
                            <Input label='Blog Category' name='cat' minLength={2} maxLength={50} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Blog Category : eg. Education or Fashion or CBSE' />
                        </div>
                        <div className="col-md-3">
                            <Input label='Status' name='status' required={true} register={register} errors={errors} elementType='select' options={statusOptions} />
                        </div>
                        <div className="col-md-3"> <Input elementType='checkbox' checked={showInPopUP === 'yes'} value="Show In Pop-UP" onChange={showInPopUpChangeHandler} /></div>

                    </div>

                    <Editor
                        apiKey={coreContext.key2}
                        value={content}
                        init={{
                            height: 500,
                            menubar: 'insert',
                            file_picker_types: 'file image media',
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor forecolor  | fontsize fontfamily lineheight | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent image | removeformat | code | help'
                        }}
                        onEditorChange={handleEditorChange}
                    />

                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                    <Input variant='danger' label={coreContext.message} elementType='label' />

                </Form>
            </div>
        </div>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Upload Blog Images</Badge></h3></div>
            <div className="card-title mx-auto">Best Image Size 500*333</div>
            <div className="card-body">
                <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='500' pictures={selectedImages} />
                <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </div>
            <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Blog Images</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
    </div>
    )
}

export { SingleBlog }