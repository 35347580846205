/* eslint-disable no-multi-str */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import ImageUploader from './common/UploadImage';
import { Editor } from '@tinymce/tinymce-react';
import Input from './common/Input';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Badge, Form } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";
import { SingleGeneralImage } from './SingleGeneralImage';

const SingleGeneral = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [content, setContent] = useState('');
    const [images, setImages] = useState([]);
    const [edate, setEdate] = useState('');
    const [etime, setEtime] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    let { id } = useParams();

    const fetchClient = () => {
        coreContext.fetchGeneral(location, authContext.itemId);
    }

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({});

    useEffect(fetchClient, []);

    const populateClient = () => {
        setValue('title', coreContext.general.title);
        setMessage(coreContext.general.message);
        setContent(coreContext.general.pageContent);
        setValue('edate', coreContext.general.edate);
        setValue('etime', coreContext.general.etime);
        setImages(coreContext.general.general_images);
    }

    useEffect(populateClient, [coreContext.general]);

    const renderImages = () => {
        if (images)
            return images.map(image => <SingleGeneralImage key={image.id} image={image} />)
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', authContext.itemId);
            formData.append('generalId', coreContext.general.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadGeneralImage(formData, authContext.itemId, coreContext.general.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchGeneralImages(authContext.itemId, coreContext.general.id);
                }, 2000);

                setTimeout(() => {
                    navigate('/generals');
                }, 5000);
            };
        }
    }



    const onMessageChangedHandler = (content, editor) => {
        setMessage(content);
    }

    const handleEditorChange = (content, editor) => {
        setContent(content);
    }




    const onGeneralContentHandler = (data) => {
        coreContext.updateGeneralContent(data.title, message, content, data.edate, data.etime, authContext.itemId, coreContext.general.id);

    }

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Edit General Item</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onGeneralContentHandler)} noValidate>
                    <Input label='Title' value={title} name='title' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Title' />


                    <Form.Group>
                        <Editor
                            apiKey={coreContext.key2}
                            value={message ? message : "Enter Message"}
                            init={{
                                height: 200,
                                menubar: 'insert',
                                file_picker_types: 'file image media',
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor forecolor | fontsize fontfamily lineheight | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent image | removeformat | code | help'
                            }}
                            onEditorChange={onMessageChangedHandler}
                        />
                    </Form.Group>

                    <div className="row"><div className="col-md-6">
                        <Input label='Event Date' value={edate} name='edate' minLength={2} maxLength={50} required={true} register={register} errors={errors} elementType='date' placeholder='Enter Event Date' />
                    </div>
                        <div className="col-md-6">
                            <Input label='Inverse Display Order' value={etime} name='etime' minLength={1} maxLength={5} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Inverse Display Order eg. 5' />
                        </div></div>

                    <Editor
                        apiKey={coreContext.key2}
                        value={content}
                        init={{
                            height: 500,
                            menubar: 'insert',
                            file_picker_types: 'file image media',
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor forecolor | fontsize fontfamily lineheight | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent image | removeformat | code | help'
                        }}
                        onEditorChange={handleEditorChange}
                    />

                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                    <Input variant='danger' label={coreContext.message} elementType='label' />

                </Form>
            </div>
        </div>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Upload General Item Images</Badge></h3></div>
            <div className="card-title mx-auto">Best Image Size 500*333</div>
            <div className="card-body">
                <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='500' pictures={selectedImages} />
                <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </div>
            <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Genereal Images</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
    </div>
    )
}

export { SingleGeneral }