/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import { useForm } from 'react-hook-form';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';

import { ToastContainer } from 'react-toastify';
import axios from 'axios';


const Settings = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({});

    const [cuPass, setCuPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [conPass, setConPass] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [emailMatch, setEmailMatch] = useState(true);
    const [message, setMessage] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [conEmail, setConEmail] = useState('');



    const fetchClient = () => {
        coreContext.fetchSelf(authContext.itemId);
    }

    const changePasswordHandler = () => {
        authContext.verifyCurrentPassword(coreContext.client.item_email, cuPass, newPass);
    }


    useEffect(fetchClient, []);

    const onCheckCurrentPassword = (e) => {
        // alert('checking current password');
        setCuPass(e.target.value);
        authContext.verifyCurrentPassword(coreContext.client.item_email, e.target.value, '');

    }

    const onNewPasswordChange = (e) => {
        setNewPass(e.target.value);
        onCheckNewPassword(e.target.value, conPass);
    }

    const onConfirmPasswordChange = (e) => {
        setConPass(e.target.value);
        onCheckNewPassword(newPass, e.target.value);
    }

    const onCheckNewPassword = (np, cp) => {

        if (np !== cp) {
            setPasswordMatch(false);
            setMessage('Passwords do not match !');
        } else {
            setPasswordMatch(true);
            setMessage('');
        }
    }


    const onNewEmailChange = (e) => {
        setNewEmail(e.target.value);
        onCheckNewEmail(e.target.value, conPass);
    }

    const onConfirmEmailChange = (e) => {
        setConEmail(e.target.value);
        onCheckNewEmail(newEmail, e.target.value);
    }

    const onCheckNewEmail = (np, cp) => {

        if (np !== cp) {
            setEmailMatch(false);
            setMessage('Emails do not match !');
        } else {
            setEmailMatch(true);
            setMessage('');
        }
    }

    const changeEmailHandler = () => {
        axios.post('change-email', { email: coreContext.client.item_email, newEmail }).then(() => alert('Email Changed Successfully !')).catch(err => alert(err));
    }


    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Settings</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(changePasswordHandler)} noValidate>
                    <div className='row'>

                        <div className="col-md-2"><h5><Badge variant='danger'>Reset Password</Badge></h5></div>
                        <div className="col-md-3">
                            <Form.Label><h6><Badge bg='dark'>Enter Current Password</Badge></h6></Form.Label>
                            <input className='form-control' type='password' onChange={e => setCuPass(e.target.value)} name='cupass' value={cuPass} minLength={2} maxLength={55} required={true} onBlur={onCheckCurrentPassword} register={register} errors={errors} />
                            <Input variant='danger' label={authContext.message} elementType='label' />
                        </div>
                        <div className="col-md-3">
                            <Form.Label><h6><Badge bg='dark'>Enter New Password</Badge></h6></Form.Label>
                            <input className='form-control' readOnly={!authContext.currPassVerified} name='newpass' value={newPass} minLength={2} maxLength={55} required={true} register={register} errors={errors} elementType='password' placeholder='Enter New Password' onChange={onNewPasswordChange} />
                        </div>
                        <div className="col-md-3">
                            <Form.Label><h6><Badge bg='dark'>Confirm New Password</Badge></h6></Form.Label>
                            <input className='form-control' readOnly={!authContext.currPassVerified} name='conpass' value={conPass} minLength={2} maxLength={55} required={true} register={register} errors={errors} elementType='password' placeholder='Confirm New Password' onChange={onConfirmPasswordChange} />
                            <Input variant='danger' label={message} elementType='label' />
                        </div>
                        <div className="col-md-1">  <Input style={{ marginTop: '40px' }} elementType='button' disabled={!authContext.currPassVerified || !passwordMatch} variant='primary' value="Update" /></div>
                    </div>
                </Form>
            </div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(changeEmailHandler)} noValidate>
                    <div className='row'>
                        <div className="col-md-2"><h5><Badge variant='danger'>Change Email</Badge></h5></div>
                        <div className="col-md-3">
                            <Form.Label><h6><Badge bg='dark'>Enter Current Password</Badge></h6></Form.Label>
                            <input className='form-control' type='password' onChange={e => setCuPass(e.target.value)} name='cupass' value={cuPass} minLength={2} maxLength={55} required={true} onBlur={onCheckCurrentPassword} register={register} errors={errors} />
                            <Input variant='danger' label={authContext.message} elementType='label' />
                        </div>
                        <div className="col-md-3">
                            <Form.Label><h6><Badge bg='dark'>Enter New Email</Badge></h6></Form.Label>
                            <input className='form-control' readOnly={!authContext.currPassVerified} name='newemail' value={newEmail} minLength={10} maxLength={55} required={true} register={register} errors={errors} elementType='email' placeholder='Enter New Email' onChange={onNewEmailChange} />
                        </div>
                        <div className="col-md-3">
                            <Form.Label><h6><Badge bg='dark'>Confirm New Email</Badge></h6></Form.Label>
                            <input className='form-control' readOnly={!authContext.currPassVerified} name='conemail' value={conEmail} minLength={10} maxLength={55} required={true} register={register} errors={errors} elementType='email' placeholder='Confirm New Email' onChange={onConfirmEmailChange} />
                            <Input variant='danger' label={message} elementType='label' />
                        </div>
                        <div className="col-md-1">  <Input style={{ marginTop: '40px' }} elementType='button' disabled={!authContext.currPassVerified || !passwordMatch} variant='primary' value="Update" /></div>
                    </div>
                </Form>
            </div>
        </div>
        <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={true}
            type='success'
        />
    </div>
    )
}

export { Settings }