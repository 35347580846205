/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import Input from './common/Input';
import { Pencil, XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';


const Blogs = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');


    const fetchClient = () => {
        coreContext.fetchSelfBlogs(authContext.itemId);
    }

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    useEffect(fetchClient, []);

    const populateClient = () => {
        setTitle(coreContext.client.blog_title);
        setMessage(coreContext.client.blog_message);
    }

    useEffect(populateClient, [coreContext.client]);

    const deleteBlog = (id) => {
        const r = confirm("Delete Blog ?");
        if (r === true) {
            coreContext.deleteBlog(id, coreContext.client.id);
        } else {

        }


    }

    const renderBlogs = () => {
        const blogs = coreContext.blogs;
        if (blogs)
            return blogs.map(blog => <tr key={blog.id}><td>{blog.title}</td><td>{blog.message}</td><td>{blog.bdate}</td><td><Link className="btn btn-primary btn-sm" title='Edit Blog' to={`/blog/${blog.id}`}><Pencil size="12" /></Link></td><td><button onClick={() => deleteBlog(blog.id)} type="button" className="btn btn-danger btn-sm" title="Delete Blog"> <XSquare size="12" /> </button></td></tr>)
    }

    const onTitleChangedHandler = (e) => {
        setTitle(e.target.value);
    }
    const onMessageChangedHandler = (e) => {
        setMessage(e.target.value);
    }

    const onBlogSectionHandler = (data) => {
        coreContext.updateBlogSection(title, message, authContext.itemId);

    }


    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Blogs Section</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onBlogSectionHandler)} noValidate>
                    <Input label='Title' value={title} name='title' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Title' onChange={onTitleChangedHandler} />

                    <Input label='Message' value={message} name='message' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Message' onChange={onMessageChangedHandler} />

                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()} &nbsp;&nbsp;
                    <Input variant='danger' label={coreContext.message} elementType='label' />
                    &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; <Link to="/add-blog">New Blog</Link>
                </Form>
            </div>
        </div>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Blogs</Badge></h3></div>
            <div className="card-body">
                <table className="table table-bordered table-sm">
                    <thead>
                        <tr><th>Title</th><th>Message</th><th>Date</th><th></th><th></th></tr>
                    </thead>
                    <tbody>
                        {renderBlogs()}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    )
}

export { Blogs }