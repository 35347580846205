/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import Input from './common/Input';
import { Pencil, XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const YouTubeVideos = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [videoUrl, setVideoUrl] = useState('');
    const [videos, setVideos] = useState([]);
    const [message, setMessage] = useState('');


    const fetchClient = () => {
        coreContext.fetchOnlySelf(authContext.itemId);
    }

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    useEffect(fetchClient, []);

    const populateClient = () => {
        setVideos(coreContext.client.video_links);
    }

    useEffect(populateClient, [coreContext.client]);

    const deleteVideo = (id) => {
        const r = confirm("Delete Video ?");
        if (r === true) {
            coreContext.deleteVideo(id, coreContext.client.id);
            const newVideos = videos.filter(video => video.id !== id);
            setVideos(newVideos);
        } else {

        }
    }

    const onChangeCheckBox = (video) => {
        const status = video.show_on_homepage === 'yes' ? 'no' : 'yes';
        coreContext.showVideoOnFrontPage(video.id, status, coreContext.client.id);

        const vs = videos.map(v => {
            if (v.id === video.id)
                v.show_on_homepage = status;
            return v;
        });

        setVideos(vs);
    }

    const renderVideos = () => {
        if (videos)
            return videos.map(video => <tr key={video.id}><td>{video.url}</td><td>{video.message}</td><td>
                <Input elementType='checkbox' value="Show On Front Page" checked={video.show_on_homepage === 'yes'} onChange={() => onChangeCheckBox(video)} />
            </td><td><button onClick={() => deleteVideo(video.id)} type="button" className="btn btn-danger btn-sm" title="Delete Video"> <XSquare size="12" /> </button></td></tr>)
    }

    const onURLChangedHandler = (e) => {
        setVideoUrl(e.target.value);
    }
    const onMessageChangedHandler = (e) => {
        setMessage(e.target.value);
    }

    const onVideoHandler = (data) => {
        coreContext.uploadVideoLink(videoUrl, message, authContext.itemId, authContext.userId);
        setTimeout(() => {
            fetchClient(coreContext.client.id);
        }, 2000);
    }


    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Youtube Videos Section</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onVideoHandler)} noValidate>
                    <Input label='URL' value={videoUrl} name='title' minLength={2} maxLength={500} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Youtube Video URL' onChange={onURLChangedHandler} />

                    <Input label='Message' value={message} name='message' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Message' onChange={onMessageChangedHandler} />

                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()} &nbsp;&nbsp;
                    <Input variant='danger' label={coreContext.message} elementType='label' />
                </Form>
            </div>
        </div>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Youtube Videos</Badge></h3></div>
            <div className="card-body">
                <table className="table table-bordered table-sm">
                    <thead>
                        <tr><th>Url</th><th>Message</th><th>Show On Home Page</th><th></th></tr>
                    </thead>
                    <tbody>
                        {renderVideos()}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    )
}

export { YouTubeVideos }