import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Badge } from 'react-bootstrap';
import { AuthContext } from '../context/auth-context';
import { useLocation } from 'react-router-dom';
import Input from './common/Input';



const Login = (props) => {
    const authContext = useContext(AuthContext);
    const location = useLocation();

    const login = (data) => {
        // console.log(data);
        authContext.login(data.email, data.password, location.pathname);
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm();


    return (
        <div className='container'>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-title mx-auto"><h3><Badge variant="success">Sign In</Badge></h3></div>
                        <div className="card-body">
                            <Form autoComplete='off' onSubmit={handleSubmit(login)} noValidate>
                                <Input label='Email' elementType='email' minLength={5} maxLength={55} placeholder='Enter Email' name='email' required={true} errors={errors} register={register} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} />
                                <Input label='Password' elementType='password' placeholder='Enter Password' required={true} minLength={5} maxLength={55} register={register} errors={errors} name='password' />
                                <Input elementType='button' variant='primary' /> &nbsp;&nbsp; {authContext.renderLoader()}
                                <Input variant='danger' label={authContext.message} elementType='label' />
                            </Form>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}






export { Login };