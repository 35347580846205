/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';

import Input from './common/Input';
import { Form } from 'react-bootstrap';
import { XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const SingleTestimonial = (props) => {

    const [imageTitle, setImageTitle] = useState(props.image.imagetitle);
    const [imageMessage, setImageMessage] = useState(props.image.imagemessage);


    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const fetchClient = () => {
        coreContext.fetchSelfTestimonial(authContext.itemId);
    }

    useEffect(fetchClient, []);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    // const onSubmit = data => console.log(data);

    const onImageDetailsHandler = (data) => {
        coreContext.updateTestimonialImage(imageTitle, imageMessage, authContext.itemId, props.image.id);

    }

    const onImageTitleChangedHandler = (e) => {
        setImageTitle(e.target.value);
    }

    const onImageMessageChangedHandler = (e) => {
        setImageMessage(e.target.value);
    }

    const deleteImage = () => {
        const id = props.image.id;
        const images = coreContext.testimonialImages;
        const newImages = images.filter(image => image.id !== id);
        coreContext.setTestimonialImages(newImages);
        coreContext.deleteTestimonialImage(id);
    }


    return (
        <div className="col-md-3"><img style={{ padding: 5 }} className="img-fluid" src={`http://${props.image.url}`} alt='gallery image' /> <button onClick={() => deleteImage()} style={{ position: 'absolute', top: 0, right: 15 }} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button>
            <Form autoComplete='off' onSubmit={handleSubmit(onImageDetailsHandler)} noValidate>
                <Input label='Image Title' value={imageTitle} name='imagtitle' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Title' onChange={onImageTitleChangedHandler} />

                <Input label='Image Message' value={imageMessage} name='imagemessage' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Message' onChange={onImageMessageChangedHandler} />

                <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </Form>

        </div>
    )
}

export { SingleTestimonial }