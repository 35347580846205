import React, { useState, useContext } from "react";
import { Form, Badge } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Input from './common/Input';
import { AuthContext } from '../context/auth-context';


const SignUp = (props) => {

    const authContext = useContext(AuthContext);


    const { register, handleSubmit, formState: { errors } } = useForm();
    // const onSubmit = data => console.log(data);

    const signUpHandler = (data) => {
        //  console.log(name, email, password);
        const { name, email, password } = data;
        authContext.signUp(name, email, password);
        // console.log(data);
    }




    return (
        <div className='container'>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-title mx-auto"><h3><Badge variant="success">Sign Up A New Client</Badge></h3></div>
                        <div className="card-body">
                            <Form autoComplete='off' onSubmit={handleSubmit(signUpHandler)} noValidate>
                                <Input label='Name' name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' />
                                <Input label='Email' name='email' required={true} register={register} errors={errors} elementType='email' minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' />
                                <Input label='Password' required={true} register={register} errors={errors} name='password' minLength={5} maxLength={55} elementType='password' placeholder='Enter Password' />
                                <Input elementType='button' variant='primary' /> &nbsp;&nbsp; {authContext.ren}
                                <Input variant='danger' label={authContext.message} elementType='label' />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}



export { SignUp };